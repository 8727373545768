body {
  margin: 0;
  font-family: 'Helvetica', 'Helvetica Bold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: #000000;
}

code {
  font-family: 'Centra No2', 'Centra No2 Bold';
}

#root {
  height: 100%;
}

input {
  font-family: 'Open Sans', 'Open Sans Italic' !important;
}