.App {
  background-color: #000000;
  min-height: 100vh;
  min-width: 100vw;
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: space-evenly;
  justify-content: center;
  color: white;
}
