@font-face {
    font-family: 'Centra No2';
    src: url(CentraNo2-Normal.ttf);
}

@font-face {
    font-family: 'Centra No2 Bold';
    src: url(CentraNo2-Bold.ttf);
} 

@font-face {
    font-family: 'Open Sans';
    src: url(OpenSans.ttf);
}

@font-face {
    font-family: 'Open Sans Italic';
    src: url(OpenSans-Italic.ttf);
}